<script lang="ts" setup>
const runtimeConfig = useRuntimeConfig();

const isUserSaved = useCookie(`#${runtimeConfig.public.APP_ID}-authorization`);
</script>
<template>
  <div>
    <div v-if="isUserSaved" class="bg-lime-500 text-center p-1 uppercase">Logged
      <Button size="sm" variant="link">
        <NuxtLink to="/admin/items">Admin/Items</NuxtLink>
      </Button>
    </div>
    <div class="flex justify-between items-center bg-zinc-700 px-6 py-2 sticky top-0 z-50">
      <Button class="text-white" size="icon" variant="ghost">
        <Icon name="solar:hamburger-menu-line-duotone" size="24" />
      </Button>

      <span class="flex bg-red-500 leading-none items-center px-2 py-1 text-sm text-white rounded-full justify-center">Mesa 5</span>

      <Button class="text-white" size="icon" variant="ghost">
        <Icon name="solar:magnifer-linear" size="20" />
      </Button>
    </div>
    <div class="p-4 w-full max-w-dvw overflow-hidden">
      <NuxtPage />
    </div>
  </div>
</template>